<template>
    <div class="bg-black text-white">{{ text }}</div>
</template>

<script>
    export default {
  name: 'BoutonChoix',
  props: {
    text: String
  }
}
</script>