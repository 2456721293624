<template>
    <section class="justify-center items-center text-center h-screen" id="nav">
        <div class="bg-primary h-[15%]">
            
        </div>
        
        <div class="flex bg-slate-50 justify-center items-center w-full h-[70%]">
            <div class=" w-[35%] xs:w-[15%] grid grid-cols-1 justify-items-center">
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Accueil"/>
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Présentation"/>
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Chansons"/>
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Vidéos"/>
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Actualités"/>
                <BoutonChoix class="w-[120px] sm:w-[200px]  lg:w-[250px] p-3 my-4 rounded-lg shadow-lg cursor-pointer" text="Contact"/>
            </div>
            <div class="grid grid-cols-1 w-[65%] sm:w-[75%] xs:w-[85%] items-center justify-items-center">
                <h1 class="flex text-4xl text-black items-center justify-center font-thin italic h-full w-full pb-4">Marine Gilleron</h1>
                <img class="flex text-center max-h-[500px] xs:max-h-80 p-4 border justify-items-center items-center" src="../assets/images/DSC_0010_1.png" alt="Marine Gilleron">
            </div>
            
        </div>
        <div class="bg-primary h-[15%]">
            <h1 class="text-lg text-black font-thin flex items-center justify-center h-full">© CM-PROD</h1>
        </div>
    </section>
</template>

<script>
    import BoutonChoix from '../components/BoutonChoix.vue'

    export default {
        components: {
            BoutonChoix
        }
    }
</script>

