<template>
  <NavigationBar id="nav"/>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import './assets/tailwind.css'

export default {
  name: 'App',
  components: {
    NavigationBar
  }
}
</script>


